@import './colors.css';
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab');

body {
  font-family: 'Lato' !important;
  font-size: 14pt !important;
}

h1 {
  font-family: 'Roboto Slab' !important;
  font-size: 30pt !important;
  color: var(--h1-color);
}

h2 {
  font-family: 'Roboto Slab' !important;
  font-size: 26pt !important;
  color: var(--h2-color);
}

a {
  text-decoration: none;
}
a,
a:visited {
  color: var(--button-standard-blue) !important;
}

a:hover {
  color: var(--button-hover-blue) !important;
  text-decoration: none !important;
}

footer a,
footer a:visited {
  color: var(--blue-lighter) !important;
}

footer a:hover {
  color: var(--blue-lightest) !important;
  text-decoration: none !important;
}

footer hr {
  border-color: var(--navy-lightest) !important;
}

/* React Radio */
.radioGroup {
  margin-top: 8px;
  margin-bottom: 8px;
}
.radioGroup > div {
  border-width: 0 !important;
  margin-bottom: 0 !important;
  padding: 8px 16px !important;
}

.radioGroup > div > div {
  justify-content: center;
  align-items: center;
}
